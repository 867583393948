import React from "react";

// Marker component
export const Marker = () => {
    const markerStyle = {
    };
    
      return (
          <React.Fragment>
              <div style={markerStyle}>
                 <img src="http://maps.google.com/mapfiles/ms/icons/blue.png" alt="marker" />
              </div>
          </React.Fragment>
      );
  };