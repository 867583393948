import axios from 'axios';

const setDefaultHeader = ( ) => {
	axios.interceptors.request.use(function (config) {
		if( config.method === "get" ){
			config.headers['Content-Type'] = 'application/json';
			config.data = {};
		}
		return config;
	});
};

export default setDefaultHeader;
