

import React, { useEffect, useState } from "react";
import "../UserProfile/profile.css";
import axios from "axios";

export default ({ user , fetchData}) => {
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    firstName: user?.firtsName || "",
    lastName: user?.lastName || "",
    image: user?.image || "", 
  });
  console.log(user)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      image: selectedImage,
    }));
  };

  const handleUpdate = async () => {
    const reqPacket = {
      userId: user.userId,
      firstName: formData.firstName,
      lastName: formData.lastName,
 
    };

    const formDataForUpdate = new FormData();
    formDataForUpdate.append("request", JSON.stringify(reqPacket));

   
    if (formData.image) {
      formDataForUpdate.append("file", formData.image);
    } else {
      formDataForUpdate.append("file", null);
    }

    try {
      const response = await axios.post(
        "https://xp.life:8443/eats/apistore/updateVar",
        formDataForUpdate
      );
if(response.status === 200){

  setEditMode(false);
  fetchData()

}
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }

  };

  useEffect(() => {
    // You can add any initialization logic here
  }, []);

  return (
    <div>
      <div className="profile-info ">
        <div
          className="top-part"
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
          }}
        >
          Profile Info
          {!editMode && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setEditMode(true)}
            >
               <svg
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title />

                <g id="Complete">
                  <g id="edit">
                    <g>
                      <path
                        d="M20,16v4a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V6A2,2,0,0,1,4,4H8"
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />

                      <polygon
                        fill="none"
                        points="12.5 15.8 22 6.2 17.8 2 8.3 11.5 8 16 12.5 15.8"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          )}
        </div>
        {user && (
          <>
            <div className="info-details">
              <div className="name fw-bold">First Name:</div>
              <div
                className="e-name"
                style={{ marginBottom: `${editMode ? "10px" : "0px"}` }}
              >
                {editMode ? (
                  <input
                    style={{
                      width: "100%",
                      height: "40px",
                    }}
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    readOnly={!editMode}
                  />
                ) : (
                  <> {user.firtsName}</>
                )}
              </div>
            </div>
            <div className="info-details">
              <div className="name fw-bold">Last Name:</div>
              <div
                className="e-name"
                style={{ marginBottom: `${editMode ? "10px" : "0px"}` }}
              >
                {editMode ? (
                  <input
                    style={{
                      width: "100%",
                      height: "40px",
                    }}
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    readOnly={!editMode}
                  />
                ) : (
                  <>{user.lastName}</>
                )}
              </div>
            </div>
            <div className="info-details">
              <div className="name fw-bold">Email:</div>
              {editMode ? (
                <input
                  style={{
                    width: "100%",
                    height: "40px",
                  }}
                  type="text"
                  value={user.email}
                  readOnly={!editMode}
                />
              ) : (
                <> {user.email}</>
              )}
            </div>
            <div className="info-details">
              <div className="name fw-bold">Image:</div>
              <div
                className="e-name"
                style={{ marginBottom: `${editMode ? "10px" : "0px"}` }}
              >
                {editMode ? (
            <div>
                  <input
                    style={{
                      width: "100%",
                      height: "40px",
                    }}
                    type="file"
                    accept="image/*"
                  
                    onChange={handleImageChange}
                  />
                 <img  src={typeof formData.image === "string" ? formData.image : (formData.image instanceof File || formData.image instanceof Blob) ? URL.createObjectURL(formData.image) : ''} alt="" className="img-fluid" />
            </div>
                ) : (
                  <>
                    <img
                      src={user?.image}
                      alt="user profile"
                      className="img-fluid"
                    />
                  </>
                )}
              </div>
            </div>
            <div className="info-details">
              <div className="name fw-bold">Phone:</div>
              <div
                className="e-name"
                style={{ marginBottom: `${editMode ? "10px" : "0px"}` }}
              >
                {editMode ? (
                  <input
                    style={{
                      width: "100%",
                      height: "40px",
                    }}
                    type="number"
                    name="phone"
                    value={user.contactNumber}
                    readOnly={!editMode}
                  />
                ) : (
                  <> {user.contactNumber}</>
                )}
              </div>
            </div>
          </>
        )}
        {editMode && (
          <>
            <button
              type="submit"
              onClick={handleUpdate}
              style={{
                borderRadius: "2px",
                height: "48px",
                border: "none",
                width: "100%",
                display: "block",
                backgroundColor: "#619f32",
                color: "#fff",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              className="checkout-btn paynow"
            >
              UPDATE
            </button>
            <button
              type="submit"
              style={{
                borderRadius: "2px",
                height: "48px",
                border: "none",
                width: "100%",
                display: "block",
                backgroundColor: "#d73829",
                color: "#fff",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              onClick={() => setEditMode(false)}
              className="checkout-btn paynow"
            >
              CANCEL
            </button>
          </>
        )}
      </div>
    </div>
  );
};
