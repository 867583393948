import React, { Component } from "react";
import { withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import PrivateRoute from "./components/common/PrivateRoute";
import PublicRoute from "./components/common/PublicRoute";
import { logoutUser, setCurrentUser } from "./store/auth/actions";
import Auth from "./pages/Auth";
import Index from "./pages";

class App extends Component {
  state = {
    publicRoutes: ["/login"],
    privateRoutes: [
      "/dashboard",
      "/location",
      "/add-location",
      "/edit-location/:id",
      "/location-details-:id",
      "/location-status",
      "/profile",
    ],
  };

  render() {
    const { onSetCurrentUser, onLogoutUser } = this.props;
    const { publicRoutes, privateRoutes } = this.state;
    if (localStorage.user) {
      onSetCurrentUser(JSON.parse(localStorage.user));
    } else {
      onLogoutUser();
    }

    return (
      <Switch>
        {publicRoutes.map((route, idx) => (
          <PublicRoute exact key={idx} path={`${route}`} component={Auth} />
        ))}
        {privateRoutes.map((route, idx) => (
          <PrivateRoute exact key={idx} path={`${route}`} component={Index} />
        ))}
        <Redirect to="/dashboard" />
      </Switch>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetCurrentUser: (user) => dispatch(setCurrentUser(user)),
    onLogoutUser: () => dispatch(logoutUser()),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(App));
