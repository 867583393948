import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getLocationsByUserId,
  // addToCart,
  // deleteCartItem,
  // getLocationByCity,
} from "../../store/location/actions";

import SingleLocation from "./SingleLocation";
import MarkerInfoWindow from "../Location/components/googleMap/gMap";
import _ from "lodash";

export default function Index(props) {
  const searchParams = new URLSearchParams(props.location.search);
  const cityName = searchParams.get("cityName");
  const countryName = searchParams.get("countryName");
  const stateName = searchParams.get("stateName");
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location);
  console.log(location);
  const { user } = useSelector((state) => state.auth);
  console.log(user);
  const { userId } = useParams();

  const [locationList, setLocationList] = React.useState([]);
  const [mapLocationList, setMapLocationList] = React.useState([]);
  const [selectedCards, setSelectedCards] = React.useState({});

  useEffect(() => {
    if (cityName && countryName) {
      const reqPacket = {
        cityName,
        countryName,
        stateName,
      };
      // dispatch(getLocationByCity(reqPacket));
    } else {
      const reqPacket = {
        userId: user.userId,
        userDetail: "admin",
      };
      console.log(reqPacket);
      dispatch(getLocationsByUserId(reqPacket));
    }
  }, [cityName]);

  useEffect(() => {
    if (location.locationList && location.locationList.length) {
      setLocationList(
        location.locationList.map((loc) => {
          return {
            locationTitle: loc.title,
            locationDetails: loc.locationAddress,
            locationPrice: loc.tabletFee,
            locationDistance: loc.kmRange,
            locationImage: loc.image,
            locationTax: loc.tax,
            locationId: loc.locationId,
            locationThirdpartyId: loc.locationThirdpartyId,
          };
        })
      );
      setMapLocationList(location.locationList);
    } else {
      setLocationList([]);
    }
  }, [location.locationList]);

  // const addLocationToCart = (location) => {
  //   const updatedLocation = { ...location };
  //   setSelectedCards({
  //     ...selectedCards,
  //     [`${updatedLocation.itemId}`]: updatedLocation,
  //   });
  // };

  // const removeLocationFromCart = (locationId) => {
  //   const index = _.findIndex(
  //     location.cartItemsList.cartItemsList,
  //     (obj) => obj.itemId === locationId
  //   );
  //   if (index >= 0) {
  //     onRemoveItemFromCart(location.cartItemsList.cartItemsList[index].cartId);
  //   } else {
  //     const clonedCards = { ...selectedCards };
  //     delete clonedCards[locationId];
  //     setSelectedCards(clonedCards);
  //   }
  // };

  // const onRemoveItemFromCart = (cartId) => {
  //   const reqPacket = {
  //     cartId,
  //     userId: user.userId,
  //   };
  //   dispatch(deleteCartItem(reqPacket));
  // };

  // const onAddToCard = () => {
  //   let cartItems = [];
  //   for (const key in selectedCards) {
  //     cartItems = [
  //       ...cartItems,
  //       {
  //         ...selectedCards[key],
  //         itemPrice: Number(selectedCards[key].itemPrice),
  //       },
  //     ];
  //   }
  //   const reqPacket = {
  //     itemList: cartItems,
  //   };
  //   dispatch(addToCart(reqPacket, user.userId));
  // };

  // const isAddedIntoCard = (locationId) => {
  //   if (
  //     _.findIndex(
  //       location.cartItemsList.cartItemsList,
  //       (obj) => obj.itemId === locationId
  //     ) >= 0
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  return (
    <div className="location-container">
      <div className="location-section mb-5" style={{ overflow: "auto" }}>
        <div className="location-cards">
          {locationList &&
            locationList.map((location, key) => (
              <div
                key={key}
                className="single-location "
                // style={{

                //   width: "40%",
                // }}
              >
                <SingleLocation
                  style={{
                    position: "absolute",
                  }}
                  {...location}
                  user={user}
                  // isAddedIntoCard={isAddedIntoCard}
                  // addItemToCart={addLocationToCart}
                  // removeItemFromCart={removeLocationFromCart}
                />
              </div>
            ))}
        </div>
        {/* {
          locationList.length ?
          <button
          className="location-cart-btn"
          type="button"
          onClick={onAddToCard}
        >
          ADD TO CART
        </button>: <button
          className="location-cart-btn"
          type="button"
          onClick={onAddToCard}
        >
          NO DATA FOUND
        </button>
        } */}
      </div>
      {locationList.length && (
        <div className="location-map">
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3400.4690277872905!2d74.33367485022342!3d31.538740281271977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391904eaa5532d49%3A0x5854b478352230e6!2sJail%20Road%2C%20Lahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1616831884286!5m2!1sen!2s"
            width={600}
            height={450}
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
          /> */}
          <MarkerInfoWindow p={mapLocationList} />
        </div>
      )}
    </div>
  );
}
