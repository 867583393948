import React, { useEffect } from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getLocationDetail } from '../../store/location/actions';

import GoogleMapReact from 'google-map-react';
import { Marker } from '../Location/components/googleMap/singleMarker';
import { useParams } from 'react-router-dom';
const defaultProps = {
  center: {
    lat: 59.95,
    lng: 30.33,
  },
  zoom: 11,
};
const Index = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { locationDetail } = useSelector((state) => state.location);

  const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
  const latitude = 49.288219;
  const longitude = -123.115723;

  useEffect(() => {
    const reqPacket = {
      locationId: id,
    };
    dispatch(getLocationDetail(reqPacket));
  }, []);

  return (
    <div>
      <div className="page6-custom">
        <div className="page-6-container">
          <div className="home-top-section mb-2">
            <div className="home-t">
              {locationDetail.title && locationDetail.title}
            </div>
            {/* <div className="home-price">
              <select
                value={locationDetail.statusId && locationDetail.statusId}
              >
                <option value={5}>Active</option>
                <option value={6}>Inactive</option>
              </select>
            </div> */}
          </div>
          <div className="home-address-details">
            <div className="home-address-desc">
              Address:{' '}
              {locationDetail.locationAddress && locationDetail.locationAddress}
            </div>
            <div className="home-distance">
              <span className="price">
                ${locationDetail.tabletFee && locationDetail.tabletFee} CAD
              </span>
              <span className="location">
                {locationDetail.kmRange && locationDetail.kmRange}KM
              </span>
            </div>
          </div>
          <div className="house-detail-banner">
            {/* <img src={locationDetail.image ? locationDetail.image : "images/house-detail.jpeg"} alt="house" /> */}
            {locationDetail.image !== 'image' ? (
              <img src={locationDetail.image} alt="user" />
            ) : (
              <img src="/images/placeholder.png" alt="user" />
            )}
          </div>
          <div className="house-detail-description">
            <div className="t-t-t">Description</div>
            <p className="desc-p">
              {' '}
              {locationDetail.description && locationDetail.description}{' '}
            </p>
          </div>
          <div
            className="house-map"
            style={{ position: 'relative', height: '450px' }}
          >
            <GoogleMapReact
              style={{ border: 0 }}
              bootstrapURLKeys={{ key: googleMapKey }}
              defaultCenter={defaultProps.center}
              center={{
                lat: locationDetail.latitude
                  ? locationDetail.latitude
                  : latitude,
                lng: locationDetail.longitude
                  ? locationDetail.longitude
                  : longitude,
              }}
              defaultZoom={defaultProps.zoom}
            >
              <Marker
                lat={
                  locationDetail.latitude ? locationDetail.latitude : latitude
                }
                lng={
                  locationDetail.longitude
                    ? locationDetail.longitude
                    : longitude
                }
              />
            </GoogleMapReact>
            <div className="cart-btn-container">
              <button type="button" className=" btn btn-danger btn-block">
                ADD TO CART
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
