import React, { Component } from "react";
import { Route } from "react-router-dom";

import Dashboard from "./Dashboard";
import Location from "./Location";
import Location1 from "../pages/AdminLocationGridView";
import AddLocation from "./AddLocation";
import LocationDetails from "./LocationDetails";
import LocationStatus from "./LocationStatus";
import UserProfile from "./UserProfile/index";
import EditLocation from "./EditLocation/EditLocation";

class routes extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/dashboard" component={Dashboard} />

        {/* Start: Location */}
        {/* <Route exact path="/location" component={Location} /> */}
        <Route exact path="/location" component={Location1} />
        <Route exact path="/add-location" component={AddLocation} />
        <Route exact path="/edit-location/:id" component={EditLocation} />
        <Route exact path="/location-details-:id" component={LocationDetails} />
        <Route exact path="/location-status" component={LocationStatus} />
        <Route exact path="/profile" component={UserProfile} />
        {/* End: Location */}
      </React.Fragment>
    );
  }
}
export default routes;
