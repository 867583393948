import axios from 'axios';

import { SET_CURRENT_USER, CLEAR_CURRENT_USER } from './types';

import { setPageLoading, clearPageLoading } from '../page/actions';

import { setErrors, clearErrors } from '../errors/actions';
import { Success } from '../../components/common/toastify';
const BACKEND_SERVER_URL = process.env.REACT_APP_API_URL;


// Set logged in user (Verified)
export const setCurrentUser = (decoded) => {
  if (!localStorage.user) {
    localStorage.setItem('user', JSON.stringify(decoded));
  }
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const clearCurrentUser = () => {
  return {
    type: CLEAR_CURRENT_USER,
  };
};

// Log user out (Verified)
export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('user');
  dispatch(clearCurrentUser());
};

// Login
export const login = (reqPacket, history) => (dispatch) => {
  dispatch(setPageLoading());
  axios
    .post(BACKEND_SERVER_URL + '/login', reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      console.log(data);
      if (resultCode === '200') {
        dispatch(setCurrentUser(data));
        dispatch(clearErrors());
        history.push(`/dashboard`);
        Success('Successfully logged in!.');
      } else {
        console.log("---------------------------");
        
        dispatch(
          setErrors({
            message: 'Invalid Credentials',
          })
        );
      }
    })
    .catch((err) => {
      dispatch(setErrors(err))})
    .finally(() => dispatch(clearPageLoading()));
};
