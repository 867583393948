import axios from "axios";
import setDefaultHeader from "../../utils/setDefaultHeader";

import { SET_DASHBOARD_DATA } from "./types";

import { setPageLoading, clearPageLoading } from "../page/actions";

import { setErrors, clearErrors } from "../errors/actions";

const BACKEND_SERVER_URL = process.env.REACT_APP_API_URL || "/";

// showDashboardBuyIn - Get dashboard data from backend
export const getDashboardData = (reqPacket) => (dispatch) => {
  dispatch(setPageLoading());
  setDefaultHeader();
  console.log(reqPacket, "got ids here");
  axios
    .get(
      `${BACKEND_SERVER_URL}/showDashboardBuyUserAndRole?userId=${reqPacket?.userId}&roleId=${reqPacket?.roleId}`
    )
    .then((res) => {
      const { resultCode, data } = res.data;
      console.log(data);
      console.log(data);
      if (resultCode === "200") {
        dispatch({
          type: SET_DASHBOARD_DATA,
          payload: data,
        });
        dispatch(clearErrors());
      } else {
        dispatch(
          setErrors({
            message: "SomeThing Went Wrong! While fetching dashboard data.",
          })
        );
      }
    })
    .catch((err) => dispatch(setErrors(err)))
    .finally(() => dispatch(clearPageLoading()));
};
