import React from 'react';

import Nav from '../components/layouts/nav';
import Footer from '../components/layouts/footer';
import Routes from './routes';
import Navbar from '../components/layouts/Navbar';

const Index = (props) => {
  return (
    <div>
      {/* Start Nav  */}
      <Nav />
      {/* <Navbar/> */}
     
      {/* End Nav */}

      {/* Start Body */}
      <Routes {...props} />
      {/* End Body */}

      {/* Start Footer */}
      <Footer />
      {/* End Footer */}
    </div>
  );
};

export default Index;
