import setDefaultHeader from "../../utils/setDefaultHeader";
import {
  SET_USER_ALL_LOCATION_DATA,
  DELETE_USER_LOCATION,
  SET_THIRD_PARTY_LOCATIONS,
  SET_SINGLE_LOCATION_DETAIL,
} from "./types";

import axios from "axios";
import { setPageLoading, clearPageLoading } from "../page/actions";

import { setErrors, clearErrors } from "../errors/actions";
import { Success } from "../../components/common/toastify";

const BACKEND_SERVER_URL = process.env.REACT_APP_API_URL || "/";

// getLocationsByUserId - Get getLocationsByUserId data from backend
export const getLocationsByUserId = (reqPacket) => (dispatch) => {
  dispatch(setPageLoading());
  setDefaultHeader(true);
  console.log(reqPacket);
  axios
    .post(BACKEND_SERVER_URL + "/getLocationsByUserId", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      console.log(res.data);
      if (resultCode === "200") {
        dispatch({
          type: SET_USER_ALL_LOCATION_DATA,
          payload:
            data && data.locationList && data.locationList.length
              ? data.locationList
              : [],
        });
        dispatch(clearErrors());
      } else {
        dispatch(
          setErrors({
            message: "SomeThing Went Wrong! While fetching user locations.",
          })
        );
      }
    })
    .catch((err) => dispatch(setErrors(err)))
    .finally(() => dispatch(clearPageLoading()));
};

export const deleteLocation = (reqPacket) => (dispatch) => {
  dispatch(setPageLoading());
  setDefaultHeader(true);
  axios
    .post(BACKEND_SERVER_URL + "/deleteLocation", reqPacket)
    .then((res) => {
      const { resultCode } = res.data;
      if (resultCode === "200") {
        Success("Successfully deleted!");
        dispatch({
          type: DELETE_USER_LOCATION,
          payload: reqPacket.locationId,
        });
        dispatch(clearErrors());
      } else {
        dispatch(
          setErrors({
            message: "SomeThing Went Wrong! While fetching user locations.",
          })
        );
      }
    })
    .catch((err) => dispatch(setErrors(err)))
    .finally(() => dispatch(clearPageLoading()));
};

// export const addLocation = (reqPacket, history) => (dispatch) => {
//   dispatch(setPageLoading());
//   setDefaultHeader(true);
//   console.log(reqPacket);
//   axios
//     .post(BACKEND_SERVER_URL + "/addLocation", reqPacket)
//     .then((res) => {
//       const { resultCode } = res.data;
//       if (resultCode === "200") {
//         Success("Location Successfully Added!");
//         history.push("/location");
//         dispatch(clearErrors());
//       } else {
//         dispatch(
//           setErrors({
//             message: "SomeThing Went Wrong! While fetching user locations.",
//           })
//         );
//       }
//     })
//     .catch((err) => dispatch(setErrors(err)))
//     .finally(() => dispatch(clearPageLoading()));
// };
export const addLocation = (reqPacket, history) => async (dispatch) => {
  dispatch(setPageLoading());
  setDefaultHeader(true);
  console.log(reqPacket);
  try {
    const res = await axios.post(
      BACKEND_SERVER_URL + "/addLocation",
      reqPacket
    );
    const { resultCode } = res.data;

    if (resultCode === "200") {
      Success("Location Successfully Added!");
      history.push("/location");
      dispatch(clearErrors());
    } else {
      dispatch(
        setErrors({
          message: "Something Went Wrong! While fetching user locations.",
        })
      );
    }
  } catch (err) {
    // Handle API request errors here
    dispatch(setErrors(err));
  } finally {
    dispatch(clearPageLoading());
  }
};
export const editLocation = (reqPacket, history) => async (dispatch) => {
 
  dispatch(setPageLoading());
  setDefaultHeader(true);
  console.log(reqPacket);
   for (var pair of reqPacket.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }
 
  try {
    const res = await axios.post(
      BACKEND_SERVER_URL + "/editLocation",
      reqPacket
    );
    const { resultCode } = res.data;

    if (resultCode === "200") {
      Success("Location Updated Successfully  !");
      history.push("/location");
      dispatch(clearErrors());
    } else {
      dispatch(
        setErrors({
          message: "Something Went Wrong! While fetching user locations.",
        })
      );
    }
  } catch (err) {
    // Handle API request errors here
    dispatch(setErrors(err));
  } finally {
    dispatch(clearPageLoading());
  }
};

export const getThirdPartyLocations = (id) => (dispatch) => {
  dispatch(setPageLoading());
  setDefaultHeader(true);
  console.log(id)
  const reqPacket = {
    thirdpartyIds: [id],
  };
  axios
    .post(BACKEND_SERVER_URL + "/getThirdPartyLocations", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      console.log(res.data)
      if (resultCode === "200") {
      
        dispatch({
          type: SET_THIRD_PARTY_LOCATIONS,
          payload: data,
        });
        dispatch(clearErrors());
      } else {
        dispatch(
          setErrors({
            message: "SomeThing Went Wrong! While fetching user locations.",
          })
        );
      }
    })
    .catch((err) => dispatch(setErrors(err)))
    .finally(() => dispatch(clearPageLoading()));
};

export const getLocationDetail = (reqPacket) => (dispatch) => {
  dispatch(setPageLoading());
  setDefaultHeader(true);

  axios
    .post(BACKEND_SERVER_URL + "/getLocationDetail", reqPacket)
    .then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === "200") {
        dispatch({
          type: SET_SINGLE_LOCATION_DETAIL,
          payload: data,
        });
        dispatch(clearErrors());
      } else {
        dispatch(
          setErrors({
            message: "SomeThing Went Wrong! While fetching user locations.",
          })
        );
      }
    })
    .catch((err) => dispatch(setErrors(err)))
    .finally(() => dispatch(clearPageLoading()));
};
