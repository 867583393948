// import React, { useEffect, useState } from "react";
// // Redux
// import { useSelector, useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
// import {
//   getThirdPartyLocations,
//   addLocation,
// } from "../../store/location/actions";
// import { getBase64 } from "../../utils/common";

// const AddLocation = () => {
//   const dispatch = useDispatch();
//   const history = useHistory();
//   const { user } = useSelector((state) => state.auth);
//   const { thirdPartyLocations } = useSelector((state) => state.location);
//   let inputElement = null;

//   // fields state
//   const [thirdpartyLocationId, setThirdpartyLocationId] = useState("");
//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");
//   const [kmRange, setKmRange] = useState("");
//   const [previewImage, setPreviewImage] = useState("");
//   const [image, setImage] = useState("image");
//   const [statusId, setStatusId] = useState("");
//   const [tabletFee, setTabletFee] = useState("");
//   const [tax, setTax] = useState("");
//   const [longitude, setLongitude] = useState("");
//   const [latitude, setLatitude] = useState("");
//   const [locationAddress, setLocationAddress] = useState("");

//   useEffect(() => {
//     dispatch(getThirdPartyLocations(user.thirdpartyId));
//   }, []);
//   const addressChangeHandler = (e) => {
//     const { value } = e.target;
//     console.log("checking value: ", value);
//     const index = thirdPartyLocations.findIndex(
//       (data) => parseInt(data.id, 10) === parseInt(value, 10)
//     );
//     const long = thirdPartyLocations[index].longitude;
//     const lat = thirdPartyLocations[index].latitude;
//     const address = thirdPartyLocations[index].address;
//     setThirdpartyLocationId(value);
//     setLongitude(long);
//     setLatitude(lat);
//     setLocationAddress(address);
//   };

//   const onSubmitHandler = (e) => {
//     e.preventDefault();
//     const reqPacket = {
//       title,
//       description,
//       kmRange,
//       image,
//       statusId,
//       tabletFee,
//       tax,
//       thirdpartyLocationId,
//       longitude,
//       latitude,
//       userId: user.userId,
//       locationAddress,
//       city: "Rawalpindi",
//       country: "Pakistan",
//       state: "Rawalpindi",
//     };
//     console.log("checking reqPacket: ", reqPacket);
//     dispatch(addLocation(reqPacket, history));
//   };

//   const imageChangeHandler = (e) => {
//     const file = e.target.files[0];
//     // setPreviewImage(file);
//     // getBase64(file, (cb) => {
//     //   const imageBase64 = cb.split(',')[1];
//     //   setImage(imageBase64);
//     // });
//   };

//   return (
//     <div>
//       <div className="page7-custom">
//         <div className="page-7 form-container">
//           <form onSubmit={onSubmitHandler}>
//             <div className="form-group">
//               <select
//                 className="form-control"
//                 name="location"
//                 placeholder="Location addres (google api auto complete)"
//                 value={thirdpartyLocationId}
//                 onChange={addressChangeHandler}
//                 required
//               >
//                 <option value={""}>XP Locations</option>
//                 {thirdPartyLocations && thirdPartyLocations.length
//                   ? thirdPartyLocations.map((data, idx) => (
//                       <option
//                         key={idx}
//                         value={data.id}
//                         data-lon={data.longitude}
//                         data-lat={data.latitude}
//                         data-address={data.address}
//                       >
//                         {data.name}
//                       </option>
//                     ))
//                   : ""}
//               </select>
//               {/* <input type="text" name="location" className="form-control" placeholder="Location addres (google api auto complete)" /> */}
//             </div>
//             <div className="form-group">
//               <input
//                 type="text"
//                 name="location"
//                 className="form-control"
//                 placeholder="Location title"
//                 value={title}
//                 onChange={(e) => setTitle(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <input
//                 type="text"
//                 name="location"
//                 className="form-control"
//                 placeholder="Table fee"
//                 value={tabletFee}
//                 onChange={(e) =>
//                   parseFloat(e.target.value) &&
//                   parseFloat(e.target.value) > 0 &&
//                   setTabletFee(e.target.value)
//                 }
//               />
//             </div>
//             <div className="form-group">
//               <input
//                 type="text"
//                 name="location"
//                 className="form-control"
//                 placeholder="KM range (circle range)"
//                 value={kmRange}
//                 onChange={(e) =>
//                   parseFloat(e.target.value) &&
//                   parseFloat(e.target.value) > 0 &&
//                   setKmRange(e.target.value)
//                 }
//               />
//             </div>
//             <div className="form-group">
//               <input
//                 type="text"
//                 name="location"
//                 className="form-control"
//                 placeholder="Tax (default 13%)"
//                 value={tax}
//                 onChange={(e) =>
//                   parseFloat(e.target.value) &&
//                   parseFloat(e.target.value) > 0 &&
//                   setTax(e.target.value)
//                 }
//               />
//             </div>
//             <div className="form-group">
//               <textarea
//                 placeholder="Description"
//                 defaultValue={""}
//                 value={description}
//                 onChange={(e) => setDescription(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <div className="select-location">
//                 <span style={{ maxWidth: "80%", overflow: "hidden" }}>
//                   {image === "" ? "Select Location Image" : previewImage.name}
//                 </span>
//                 <input
//                   id="fileid"
//                   ref={(input) => (inputElement = input)}
//                   onChange={imageChangeHandler}
//                   type="file"
//                   name="filename"
//                   accept="image/*"
//                   hidden
//                 />
//                 <input
//                   id="buttonid"
//                   onClick={() => inputElement.click()}
//                   type="button"
//                   name="file"
//                   defaultValue="UPLOAD"
//                 />
//               </div>
//             </div>
//             <div className="form-group">
//               <label>Status</label>
//               <select
//                 className="form-control"
//                 value={statusId}
//                 onChange={(e) => setStatusId(parseInt(e.target.value, 10))}
//               >
//                 <option value="">Select Status</option>
//                 <option value={5}>Active</option>
//                 <option value={6}>Inactive</option>
//               </select>
//             </div>
//             <div className="form-group">
//               <button type="submit" className="btn btn-register">
//                 SUBMIT
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddLocation;

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getThirdPartyLocations,
  addLocation,
} from "../../store/location/actions";
import { getBase64 } from "../../utils/common";

const AddLocation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { thirdPartyLocations } = useSelector((state) => state.location);
  console.log(thirdPartyLocations)


  const [thirdpartyLocationId, setThirdpartyLocationId] = useState("");
  console.log(thirdpartyLocationId)
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  // const [kmRange, setKmRange] = useState("");
  const [image, setImage] = useState("image");
  const [imageFile, setImageFile] = useState(null); // To store the selected image file
  const [statusId, setStatusId] = useState("");
  const [tabletFee, setTabletFee] = useState("");
  const [tax, setTax] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [locationAddress, setLocationAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  // Error state for form fields
  const [titleError, setTitleError] = useState("");
  const [imageError, setImageError] = useState("");
  const [kmRangeError, setKmRangeError] = useState("");
  const [tabletFeeError, setTabletFeeError] = useState("");
  const [taxError, setTaxError] = useState("");
  const [desError, setDesError] = useState("");
  const inputElementRef = useRef(null);


/////////////////////// fetch locations ///////////////////////////////

  // const fetchLocations = async (thirdpartyId) => {
  //   console.log(thirdpartyId);
  //   try {
  //     const response = await axios.post(
  //       `${BACKEND_SERVER_URL}/getThirdPartyLocations`,
  //       { thirdpartyIds: [thirdpartyId] }
  //     );
  //     console.log(response.data.data);
  //     if (response.data.data && response.data.data.length > 0) {
  //       // Filter the data to include only items with status "active"
  //       const activeLocations = response.data.data.filter(
  //         (location) => location.buyin_status === "1"
  //       );

  //       // Set the filtered data to your state variable
  //       setVarLoc(activeLocations);

  //       console.log("Active Locations:", activeLocations);
  //     } else {
  //       console.log("No data found.");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching locations:", error);
  //   }
  // };
  const activeLocations = thirdPartyLocations.filter(
    (location) => location.buyin_status === "1"
  );
  console.log(activeLocations)

  useEffect(() => {
    
    dispatch(getThirdPartyLocations(user.thirdpartyId));
  }, []);

  const addressChangeHandler = (e) => {
    const { value } = e.target;
    const index = thirdPartyLocations.findIndex(
      (data) => parseInt(data.id, 10) === parseInt(value, 10)
    );
    const long = thirdPartyLocations[index].longitude;
    const lat = thirdPartyLocations[index].latitude;
    const address = thirdPartyLocations[index].address;
    setImage(
      thirdPartyLocations[index].image_1 && thirdPartyLocations[index].image_1
    );
    setThirdpartyLocationId(value);
    setLongitude(long);
    setLatitude(lat);
    setLocationAddress(address);
    setCity(thirdPartyLocations[index].city);
    setCountry(thirdPartyLocations[index].country);
    setState(thirdPartyLocations[index].province);
  };

  const validateForm = () => {
    let isValid = true;

    if (title.trim() === "") {
      setTitleError("Please enter a title for the location.");
      isValid = false;
    } else {
      setTitleError("");
    }

    if (description.trim() === "") {
      setDesError("Please enter a description for the location.");
      isValid = false;
    } else {
      setDesError("");
    }

    if (!imageFile) {
      setImageError(
        "Please upload a valid image file (JPG/PNG) within the size limit."
      );
      isValid = false;
    } else {
      setImageError("");
    }

    // if (
    //   kmRange.trim() === "" ||
    //   isNaN(parseFloat(kmRange)) ||
    //   parseFloat(kmRange) <= 0
    // ) {
    //   setKmRangeError("Please enter a valid KM range (greater than 0).");
    //   isValid = false;
    // } else {
    //   setKmRangeError("");
    // }

    if (
      tabletFee.trim() !== "" &&
      (isNaN(parseFloat(tabletFee)) || parseFloat(tabletFee) <= 0)
    ) {
      setTabletFeeError(
        "Please enter a valid tablet fee (greater than 0) or leave it empty."
      );
      isValid = false;
    } else {
      setTabletFeeError("");
    }

    if (tax.trim() !== "" && (isNaN(parseFloat(tax)) || parseFloat(tax) <= 0)) {
      setTaxError(
        "Please enter a valid tax (greater than 0) or leave it empty."
      );
      isValid = false;
    } else {
      setTaxError("");
    }

    return isValid;
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const reqPacket = {
      title,
      description,
      // kmRange,
      statusId:"2",
      // tabletFee,
      // tax,
      image,
      thirdpartyLocationId,
      longitude,
      latitude,
      userId: user.userId,
      locationAddress,
      city,
      country,
      state,
    };
    console.log(reqPacket);
    const formData = new FormData();
    formData.append("request", JSON.stringify(reqPacket));
    console.log(imageFile)
    formData.append("file", imageFile);

    console.log("FormData:");
    for (var pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    const hasImage = formData.has("file");

    console.log("Image appended to FormData:", hasImage);

    dispatch(addLocation(formData, history));
  };

  const imageChangeHandler = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        if (file.size <= 5 * 1024 * 1024) {
          setImageFile(file);
          setImageError("");
        } else {
          setImageError("Please upload an image within the size limit (5MB).");
        }
      } else {
        setImageError("Please upload a valid image file (JPG/PNG).");
      }
    }
  };

  return (
    <div>
      <div className="page7-custom">
        <div className="page-7 form-container">
          <form onSubmit={onSubmitHandler}>
            <div className="form-group">
              <select
                className="form-control"
                name="location"
                placeholder="Location address (google api auto complete)"
                value={thirdpartyLocationId}
                onChange={addressChangeHandler}
                required
              >
                <option value={""}>XP Locations</option>
                {activeLocations && activeLocations.length
                  ? activeLocations.map((data, idx) => (
                      <option
                        key={idx}
                        value={data.id}
                        data-lon={data.longitude}
                        data-lat={data.latitude}
                        data-address={data.address}
                      >
                        {data.name}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
            <div className="form-group">
              <input
                type="text"
                name="location"
                className="form-control"
                placeholder="Location title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <span className="error-message text-danger">{titleError}</span>
            </div>
            {/* <div className="form-group">
              <input
                type="text"
                name="location"
                className="form-control"
                placeholder="Table fee"
                value={tabletFee}
                onChange={(e) =>
                  parseFloat(e.target.value) &&
                  parseFloat(e.target.value) > 0 &&
                  setTabletFee(e.target.value)
                }
              />
              <span className="error-message text-danger">
                {tabletFeeError}
              </span>
            </div> */}
            {/* <div className="form-group">
              <input
                type="text"
                name="location"
                className="form-control"
                placeholder="KM range (circle range)"
                value={kmRange}
                onChange={(e) =>
                  parseFloat(e.target.value) &&
                  parseFloat(e.target.value) > 0 &&
                  setKmRange(e.target.value)
                }
              />
              <span className="error-message text-danger">{kmRangeError}</span>
            </div> */}
            {/* <div className="form-group">
              <input
                type="text"
                name="location"
                className="form-control"
                placeholder="Tax (default 13%)"
                value={tax}
                onChange={(e) =>
                  parseFloat(e.target.value) &&
                  parseFloat(e.target.value) > 0 &&
                  setTax(e.target.value)
                }
              />
              <span className="error-message text-danger">{taxError}</span>
            </div> */}
            <div className="form-group">
              <textarea
                placeholder="Description"
                defaultValue={""}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <span className="error-message text-danger">{desError}</span>
            </div>
            <div className="form-group">
              <div className="select-location">
                <span style={{ maxWidth: "80%", overflow: "hidden" }}>
                  {imageFile === null
                    ? "Select Location Image"
                    : imageFile.name}
                </span>
                <input
                  id="fileid"
                  ref={inputElementRef}
                  type="file"
                  name="filename"
                  accept="image/jpeg,image/png"
                  hidden
                  onChange={imageChangeHandler}
                />
                <input
                  id="buttonid"
                  onClick={() => inputElementRef.current.click()}
                  type="button"
                  name="file"
                  defaultValue="UPLOAD"
                />
              </div>
              <span className="error-message text-danger">{imageError}</span>
            </div>
            {/* <div className="form-group">
              <label>Status</label>
              <select
                className="form-control"
                value={statusId}
                onChange={(e) => setStatusId(e.target.value)}
              >
                <option value="">Select Status</option>
                <option value="5">Active</option>
                <option value="6">Inactive</option>
              </select>
            </div> */}
            <div className="form-group">
              <button type="submit" className="btn btn-register">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddLocation;
