import { 
	SET_USER_ALL_LOCATION_DATA,
	DELETE_USER_LOCATION,
	SET_THIRD_PARTY_LOCATIONS,
	SET_SINGLE_LOCATION_DETAIL
 } from './types';

const initialState = {
	locationList: [],
	thirdPartyLocations: [],
	locationDetail: {}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_USER_ALL_LOCATION_DATA:
			return {
				...state,
				locationList: action.payload
			};
		case DELETE_USER_LOCATION:{
			const updatedLocationList = state.locationList.filter(data => parseInt(data.locationId, 10) !== parseInt(action.payload, 10) );
			return {
				...state,
				locationList: updatedLocationList
			};
		}
		case SET_THIRD_PARTY_LOCATIONS:
			return {
				...state,
				thirdPartyLocations: action.payload
			};
		case SET_SINGLE_LOCATION_DETAIL:
			return {
				...state,
				locationDetail: action.payload
			};
		default:
			return state;
	}
}