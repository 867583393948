import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../../store/auth/actions';
import Spinner from '../../../components/common/Loader';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((state) => state.page.loading);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const reqPacket = {
      emailAddress,
      password,
      userName: '',
    };
    dispatch(login(reqPacket, history));
  };

  return (
    <div>
      <div className="d-flex flex-column-fluid flex-center">
        <div className="login-form login-signin">
          {/*begin::Form*/}
          <img
            src="images/xp_eats.svg"
            width="200px"
            alt="logo"
            className="envision-red-logo"
          />
          <form
            onSubmit={onSubmitHandler}
            className="login-form form fv-plugins-bootstrap fv-plugins-framework"
            id="kt_login_signin_form"
          >
            <div className="pb-7 pt-lg-0 pt-5">
              <h3 className="welcome-title">Login Form</h3>
            </div>
            <div className="form-group fv-plugins-icon-container mx-1">
              <input
                className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                type="email"
                name="email"
                onChange={(e) => setEmailAddress(e.target.value)}
                required
                placeholder="Enter Email Address"
              />
            </div>
            <div className="form-group fv-plugins-icon-container mx-1">
              <input
                className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                id="password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                required
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="off"
                placeholder="Enter Password"
              />
              {/* <span
                onClick={() => setShowPassword(!showPassword)}
                className="show"
              >
                SHOW
              </span> */}
               <span
                style={{ cursor: "pointer", fontSize: "14px" }}
                className="show"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "HIDE" : "SHOW"}
              </span>
            </div>
            <div className="pb-lg-0 pb-5">
              <button
                type="submit"
                id="kt_login_signin_submit"
                className="gilroy-medium btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
              >
                SIGN IN
              </button>
            </div>
          </form>
          {/*end::Form*/}
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  );
};
export default Login;
