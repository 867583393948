import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import notFound from "../../Images/ic_not_found.svg"

const Index = () => {
  const BACKEND_SERVER_URL = process.env.REACT_APP_API_URL;

  const [locationStatus, setLocationStatus] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  console.log(locationStatus);

  const { user } = useSelector((state) => state.auth);
  // console.log(user.userId);

  const fetchLocationStatus = async (userId) => {
    try {
      const response = await axios.post(`${BACKEND_SERVER_URL}/getUserLocations`, {
        ownerId: user?.userId,
        // ownerId: 1,
      });
      setLocationStatus(response?.data.data.locationsList);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    fetchLocationStatus(user.userId);
  }, [user.userId]);

  return (
    <div>
      <div className="page11-custom">
        <div className="page-11 form-container">
          {loading ? (
            <p>Loading data...</p>
          ) : locationStatus.length === 0 ? (
          <div className=" text-center" >
            <img src={notFound} />
          </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Var Info</th>
                  <th>Buyer</th>
                  <th>Device Status</th>
                </tr>
              </thead>
              <tbody>
                {locationStatus.map((data, i) => (
                  <tr key={i}>
                    <td className="img-text-td">
                      <div className="d-flex">
                        <img src={data.locationImage} alt="" />
                        <div className="img-text-content-part">
                          <div>{data.locationTitle.substring(0, 15)}</div>
                          <div>{data.locationDescription.substring(0, 15)}</div>
                          <div>Date: {data.buyDate}</div>
                        </div>
                      </div>
                    </td>
                    <td className="price-td">
                      <div className="d-flex">
                        <div className="img-text-content-part">
                          <div>
                            {data.ownerFirstName} {data.ownerLastName}
                          </div>
                          <div>{data.address.substring(0, 15)}</div>
                          <div>Date: {data.buyDate}</div>
                        </div>
                      </div>
                    </td>
                    <td className="buyer-td">
                      <div className="d-flex">
                        <div className="img-text-content-part">
                          <div>
                            {data.buyerFirstName} {data.buyerLastName}
                          </div>
                          <div>{data.userEmail}</div>
                          <div>Date: {data.buyDate}</div>
                        </div>
                      </div>
                    </td>
                    <td className="booked-td">{data.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;








