// import React, { useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { getDashboardData } from "../../store/home/actions";
// import Spinner from "../../components/common/Loader";

// const Index = () => {
//   const dispatch = useDispatch();
//   const { dashboardData } = useSelector((state) => state.home);
//   const { loading } = useSelector((state) => state.page);
//   const { user } = useSelector((state) => state.auth);
//   const reqPacket = {
//     userId: user.userId,
//     roleId: 5,
//   };
//   console.log(dashboardData.dashboardBuyInTiles);

//   useEffect(() => {
//     dispatch(getDashboardData(reqPacket));
//   }, []);
//   return (
//     <div>
//       {loading ? (
//         <Spinner />
//       ) : (
//         <div className="page-3-container">
//           {/* <div className="create-var-column">
//             {dashboardData &&
//               dashboardData.dashboardBuyInSummary &&
//               dashboardData.dashboardBuyInSummary.map((data) => {
//                 return (
//                   <div className="single-location">
//                     <div className="t-title">{data.label}</div>
//                     {console.log(data)}
//                     <div className="t-info">
//                       <div className="number">{data.value}</div>
//                       <img src="images/032-boy-13.svg" alt="032-boy-13" />
//                     </div>
//                   </div>
//                 );
//               })}
//           </div> */}

//           <div className="income-option">
//             {dashboardData &&
//               dashboardData?.dashboardBuyInTiles &&
//               dashboardData.dashboardBuyInTiles.map((data, i) => {
//                 return (
//                   <div className="income-part" key={i}>
//                     <div
//                       style={{
//                         display: "flex ",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <span className="income-part-t">{data?.heading}</span>
//                       <span className="income-price">{data?.value}</span>
//                     </div>

//                     {/* <div className="income-exam">
//                       <div className="income-intro">{data.summary}</div>
//                       <div className="income-price">{data.summaryValue}</div>
//                     </div>
//                     <div className="income-exam">
//                       <div className="income-intro">{data.description}</div>
//                       <div className="income-price">{data.value}</div>
//                     </div> */}
//                   </div>
//                 );
//               })}
//           </div>
//         </div>
//       )}
//     </div>
//     // <div>
//     //   {loading ? (
//     //     <Spinner />
//     //   ) : (
//     //     <div className="page-3-container">
//     //       {dashboardData &&
//     //         dashboardData.dashboardBuyInSummary &&
//     //         dashboardData.dashboardBuyInSummary.map((t, index) => {
//     //           return (
//     //             <div key={index} className="single-location">
//     //               <div className="t-title">{t.label}</div>
//     //               <div className="t-info">
//     //                 <div className="number">{t.value}</div>
//     //                 <img src="images/032-boy-13.svg" alt="032-boy-13" />
//     //               </div>
//     //             </div>
//     //           );
//     //         })}

//     //       <div className="income-option">
//     //         {dashboardData &&
//     //           dashboardData.dashboardBuyInTiles &&
//     //           dashboardData.dashboardBuyInTiles.map((s, index) => {
//     //             return (
//     //               <div key={index} className="income-part">
//     //                 <div className="income-part-t">
//     //                   {s.heading}

//     //                   <span className="income-intro">{s.summary}</span>
//     //                   {/* <div className="income-price">{s.summaryValue}</div> */}
//     //                 </div>

//     //                 <div className="income-exam">
//     //                   <div className="income-intro">{s.description}</div>
//     //                   <div className="income-price">{s.value}</div>
//     //                 </div>
//     //               </div>
//     //             );
//     //           })}
//     //       </div>
//     //     </div>
//     //   )}
//     // </div>
//   );
// };

// export default Index;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardData } from "../../store/home/actions";
import Spinner from "../../components/common/Loader";
import notFound from "../../Images/ic_not_found.svg";

const Index = () => {
  const dispatch = useDispatch();
  const { dashboardData } = useSelector((state) => state.home);
  console.log(dashboardData)
  const { loading } = useSelector((state) => state.page);
  const { user } = useSelector((state) => state.auth);
  const reqPacket = {
    userId: user.userId,
    // userId: 149,
    roleId: 5,
  };

  useEffect(() => {
    dispatch(getDashboardData(reqPacket));
  }, []);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="page-3-container">
          {dashboardData ? (
            <div className="income-option">
              {dashboardData?.dashboardBuyInTiles?.map((data, i) => (
                <div className="income-part" key={i}>
                  {console.log(data)}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span className="income-part-t">{data?.heading}</span>
                    <span className="income-price">
  {data?.value === null ? 0 : data?.value}
</span>


                  
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <img src={notFound} alt="" />
          )}
        </div>
      )}
    </div>
  );
};

export default Index;
