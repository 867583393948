// import React, { useEffect } from "react";

// import { useSelector, useDispatch } from "react-redux";
// import { getUserLocations } from "../../store/location/actions";
// import {
//   loadAllCreditCards,
//   deleteCreditCard,
// } from "../../store/common/actions";
// import Loader from "../../components/common/Loader";
// import Profile from "./profile";
// import Payment from "./payment";
// import { getProfileData } from "../../store/auth/actions";
// import Product from "./product";

// export default function Index() {
//   const dispatch = useDispatch();
//   const { user } = useSelector((state) => state.auth);
//   // console.log(user);
//   const { loading } = useSelector((state) => state.page);
//   const { userLocationList } = useSelector((state) => state.location);
//   const { creditCardList } = useSelector((state) => state.common);

//   useEffect(() => {
//     const requestPacket = {
//       buyerId: `${user && user.userId ? user.userId : ""}`,
//       ownerId: "",
//       adminId: "",
//     };
//     dispatch(getUserLocations(requestPacket));

//     const reqPacket = {
//       userId: user.userId,
//     };
//     dispatch(loadAllCreditCards(reqPacket));
//   }, []);

//   const deleteCardHandler = (card) => {
//     const reqPacket = {
//       cardId: card.cardId,
//       stripeCardId: card.stripeCardId,
//       userId: user.userId,
//     };
//     dispatch(deleteCreditCard(reqPacket));
//   };
//   useEffect(() => {
//     const reqPacket = {
//       userId: `${user.userId}`,
//       userType: "user",
//     };
//     dispatch(getProfileData(reqPacket));
//   }, []);
//   return (
//     <div className="page11-custom">
//       {loading ? (
//         <Loader />
//       ) : (
//         <div className="profile-page11">
//           {/* Start Product Section */}
//           <Product locationList={userLocationList} />
//           {/* End Product Section */}

//           <div className="profile-section">
//             {/* Start Profile Section */}
//             <Profile user={user} />
//             {/* End Profile Section */}

//             {/* Start Payment Section */}
//             <Payment
//               creditCardList={creditCardList}
//               deleteCardHandler={deleteCardHandler}
//             />
//             {/* End Payment Section */}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import Profile from "./profile";
import Status from "../LocationStatus/index";
import axios from "axios";
import { useState } from "react";

export default function Index() {
  const BACKEND_SERVER_URL = process.env.REACT_APP_API_URL;
  const { user } = useSelector((state) => state.auth);
  const [userData, setUserData] = useState()
  console.log(userData);

  const fetchData = async () => {
    console.log(user.userId)
    
    try {
      const response =await axios.post(
        `${BACKEND_SERVER_URL}/getProfileData`,
       { userId:user?.userId}
      );
      setUserData(response.data.data)
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(()=>{
    fetchData()
  },[])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-8">
            <Status />
          </div>
          <div className="col-4">
            <div className="profile-section">
              <Profile user={userData} fetchData={fetchData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

{
  /* <div className="page11-custom"> */
}
{
  /* <div className="profile-page11"> */
}
{
  /* Start Product Section */
}
{
  /* <Product locationList={userLocationList} /> */
}
{
  /* End Product Section */
}

{
  /* <div className="profile-section"> */
}
{
  /* Start Profile Section */
}
{
  /* <Profile user={user} /> */
}
{
  /* End Profile Section */
}

{
  /* Start Payment Section */
}
{
  /* <Payment
      creditCardList={creditCardList}
      deleteCardHandler={deleteCardHandler}
    /> */
}
{
  /* End Payment Section */
}
{
  /* </div> */
}
{
  /* </div> */
}
{
  /* </div> */
}
