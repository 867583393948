import React from 'react';

const Footer = () => {
  return (
    <div>
      {/* <div className="line-break">
        <br />
        <br />
        <br />
        <br />
      </div> */}
      <footer>
        <div className="footer-maxwidth">
          <div className="footer-social-icons">
            {/* <span>
                        <i className="fa fa-linkedin" aria-hidden="true" />
                        </span> */}
            <a href="https://www.facebook.com/xpeats/" target="_blank">
              <span className="fb-transparent">
                <i className="fa fa-facebook-official" aria-hidden="true" />
              </span>
            </a>

            <a href=" https://www.instagram.com/xpeatsapp/" target="_blank">
              <span>
                <i className="fa fa-instagram" aria-hidden="true" />
              </span>
            </a>

            {/* <span>
                        <i className="fa fa-twitter" aria-hidden="true" />
                        </span> */}
          </div>
          <div className="footer-copywright-text">
            © XP EATS 2021 All Rights Reserved. 2020
          </div>
          <div className="footer-terms">
            <a href="https://xpeats.com/terms.html" target="_blank">
              Terms &amp; Conditions
            </a>{' '}
            &nbsp;
            <span>and</span> &nbsp;
            <a href="https://xpeats.com/privacy.html" target="_blank">
              Privacy Policy
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
