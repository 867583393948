import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import Login from './Login'

class routes extends Component {
    render() {
        return (
            <React.Fragment>
                <Route exact path="/login" component={Login} />
            </React.Fragment>
        )
    }
}
export default routes;
