import React from 'react';

export default function banner() {
  return (
    <div
      className="login-aside d-flex flex-column flex-row-auto"
      style={{ backgroundColor: '#619F32' }}
    >
      {/*begin::Aside Top*/}
      <div className="d-flex flex-column-auto flex-column pt-5">
        <img
          src="images/ic-logo-white.svg"
          alt="logo"
          className="envision-white-logo"
        />
      </div>
      {/*end::Aside Top*/}
      {/*begin::Aside Bottom*/}
      <div
        className="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
        style={{ backgroundImage: 'url(images/login-visual-1.svg)' }}
      />
      {/*end::Aside Bottom*/}
    </div>
  );
}
